<template>
    <div id="common_detail" class="common_detail">
        <div class="title" :title="taskMsg.title">
            {{ taskMsg.title }}
        </div>

        <div class="recordList scrollbar">
            <!-- 主要针对保护工程：
                有基准照--单独设立监测点--只有一张现场照，且为图片
                无基准照--不单独设立监测点--有多找现场照，可为图片、视频
            -->
            <div class="img-box" v-if="infoData.JZZ">
                <div class="left-img">
                    <el-image
                        fit="cover"
                        style="width: 100%; height: 228px"
                        :src="infoData.JZZ"
                        v-viewer
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData width="150px" typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                    <p>基准图</p>
                </div>
                <div class="right-img">
                    <el-image
                        fit="cover"
                        style="width: 100%; height: 228px"
                        :src="infoData.XXZ ? infoData.XXZ[0] : ''"
                        v-viewer
                    >
                        <div slot="error" style="width: 100%; height: 100%">
                            <SnNoData width="150px" typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                    <p>现场照</p>
                </div>
            </div>
            <div v-else>
                <el-form
                    v-if="infoData.PIC && infoData.PIC.length"
                    ref="ruleForm1"
                    class="demo-ruleForm daily-patrol-form"
                    @submit.native.prevent
                >
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="照片" label-width="45px">
                                <picVedio
                                    :picCheck="false"
                                    :picVideoList="infoData.PIC"
                                    :srcName="'CCLJ'"
                                ></picVedio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <div v-else class="no-data">
                    <SnNoData
                        width="350px"
                        :noDataState="true"
                        noDataText="暂无数据"
                        color="#e4e4e4"
                    ></SnNoData>
                </div>
            </div>
            <!-- 任务状态为已完成时显示采集字段  -->
            <el-form
                ref="ruleForm"
                v-if="taskRwzt === 1"
                class="demo-ruleForm daily-patrol-form"
            >
                <img
                    class="sh-pic"
                    :src="
                        require(`@image/monitoring_cloud/sh_${infoData.SHZT}.png`)
                    "
                    alt=""
                />
                <el-row :gutter="24">
                    <el-col
                        v-for="(item, i) in formList"
                        :key="i"
                        :class="setShow(formList, item)"
                        :span="24"
                    >
                        <el-form-item :label="item.label" :prop="item.value">
                            {{ item.value ? infoData[item.value] : "" }}
                            <audios
                                v-if="
                                    item.audioValue &&
                                    infoData[item.audioValue] &&
                                    infoData[item.audioValue].length > 0
                                "
                                :audioList="infoData[item.audioValue]"
                                srcName="CCLJ"
                            ></audios>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import audios from "./audio_show";
import picVedio from "./pic_video_show";
export default {
    name: "common_detail",
    components: {
        audios,
        picVedio,
    },
    props: {
        detailData: {
            type: Object,
            default: () => {
                let obj = {
                    PIC: [],
                    FJ_SJMS: [],
                    dt: [],
                };
                return obj;
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            taskTime: "",
            taskRwzt: "",
            taskType: "",
            jcpzid: "",
            rwid: "",
            infoData: {},
            infoDataAll: [],
            currentPage: 0,
            allPage: 0,
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            formList: [],
            formList1201: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "描述：",
                    value: "MS",
                    audioValue: "FJ_SJMS",
                },
                {
                    label: "异常是否解除：",
                    value: "SFJC_DESC",
                },
            ],
        };
    },
    watch: {
        detailData: {
            immediate: true,
            handler() {
                this.infoData = {};
                this.taskType = this.taskMsg.type || "";
                this.formList = this["formList" + this.taskType];
                if (this.detailData.dt) {
                    this.infoData = { ...this.detailData.dt[0] };
                }
                this.infoData.XXZ = [];
                let pics = this.detailData.fjbDtos || this.detailData.PIC;
                pics &&
                    pics.forEach((item) => {
                        this.infoData.XXZ.push(item.CCLJ);
                    });
                this.infoData.FJ_SJMS = this.detailData.FJ_SJMS;
                this.infoData.PIC = pics;
                this.currentPage = 1;
                if (this.infoData.XXZ && this.infoData.XXZ.length) {
                    this.allPage = this.infoData.XXZ.length;
                }
            },
            deep: true,
        },
        taskMsg: {
            handler() {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskType = this.taskMsg.type || "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
                this.formList = this["formList" + this.taskType];
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions([""]),
        setShow(list, item) {
            if (!item.judge && list[0].judge) {
                if (this.infoData[list[0].value] === "") {
                    return "hide-form-item";
                }
                return this.infoData[list[0].value] === list[0].keyword
                    ? list[0].class1
                    : list[0].class2;
            }
            return "";
        },
    },
};
</script>
<style lang="scss" scoped>
.common_detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList {
        background-color: #fff;
        height: calc(100% - 35px);
        padding: 10px;
        .img-box {
            width: 100%;
            overflow: hidden;
            .left-img,
            .right-img {
                width: 50%;
                float: left;
                text-align: center;
                p {
                    height: 34px;
                    line-height: 34px;
                    color: #336799;
                    background-color: #f6f5fa;
                    margin-top: 10px;
                    font-weight: 600;
                    position: relative;
                    .num {
                        position: absolute;
                        right: 10px;
                    }
                }
                .el-image {
                    max-width: 100%;
                    height: 180px;
                    vertical-align: top;
                }
            }
            .left-img {
                padding-right: 5px;
            }
            .right-img {
                padding-left: 5px;
            }
        }
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            position: relative;
            .sh-pic {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
            .el-form-item {
                margin-bottom: 0 !important;
            }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                    &.hide-form-item {
                        display: none;
                    }
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
        }
        .no-data {
            height: 300px;
        }
    }
}
</style>
