<template>
    <div class="audio-box">
        <div v-for="(item, i) in audioList" :key="i + 'pic'" class="audio">
            <audio :ref="'audio'" :src="item[srcName]" controls></audio>
        </div>
    </div>
</template>
<script>
export default {
    name: 'audio',
    props: {
        audioList: {
            default: function() {
                return [];
            }
        },
        srcName: {
            default: function() {
                return 'SYT';
            }
        }
    },
    data() {
        return {
        };
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    }
};
</script>
<style lang="scss" scoped>
.audio-box {

}
</style>