<template>
    <div class="picVideoBox" v-viewer>
        <div v-for="(item, i) in picList" :key="i + 'pic'" class="pic-box">
            <el-image
                style="width: 100%; height: 228px"
                :src="item"
                fit="cover"
                class="pointer"
            >
                <div slot="error" style="width:100%;height:100%;">
                    <SnNoData width="150px" typeNum="1"> </SnNoData>
                </div>
            </el-image>
            <div
                v-if="picCheck"
                class="check-box"
                :class="{ checked: item === checked }"
                @click="checkPic(item)"
            >
                <i class="fa fa-check"></i>
            </div>
        </div>
        <div
            v-for="(item, i) in videoList"
            :key="i + 'video'"
            class="video-box"
        >
            <VueDPlayer
                :options="{
                    video: {
                        url: item,
                    },
                    screenshot: true,
                }"
            ></VueDPlayer>
        </div>
    </div>
</template>
<script>
export default {
    name: "pic-video",
    props: {
        picVideoList: {
            default: function() {
                return [];
            },
        },
        srcName: {
            default: function() {
                return "CCLJ";
            },
        },
        picCheck: {
            default: function() {
                return false;
            },
        },
        jztUrl: {
            default: function() {
                return "";
            },
        },
    },
    data() {
        return {
            picList: [],
            videoList: [],
            checked: "",
        };
    },
    watch: {
        picVideoList() {
            this.getList();
        },
        jztUrl() {
            this.checked = this.jztUrl;
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            this.picList = [];
            this.videoList = [];
            this.picVideoList.forEach((item) => {
                if (this.isVideo(item[this.srcName])) {
                    this.videoList.push(item[this.srcName]);
                } else {
                    this.picList.push(item[this.srcName]);
                }
            });
        },
        isVideo(src) {
            let videoList = ["mp4", "avi", "mov"];
            let type = src.slice(src.length - 3);
            return videoList.indexOf(type.toLowerCase()) > -1;
        },
        checkPic(item) {
            this.checked = this.checked === item ? "" : item;
            this.$emit("update:jztUrl", this.checked);
        },
    },
};
</script>
<style lang="scss" scoped>
.picVideoBox {
    .pic-box {
        width: 190px;
        height: 190px;
        display: inline-block;
        margin: 0 10px 10px 0;
        position: relative;
        .check-box {
            width: 20px;
            height: 20px;
            line-height: 14px;
            background-color: transparent;
            border: 3px solid #eee;
            border-radius: 50%;
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            box-shadow: 0px 0px 3px #999;
            .fa-check {
                color: transparent;
            }
            &.checked {
                border: 3px solid #fff;
                background-color: #fff;
                .fa-check {
                    color: green;
                }
            }
        }
    }
    .video-box {
        width: 100%;
        max-width: 390px;
        margin-top: 5px;
    }
}
</style>
