<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">日常巡查异常记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="CJSJ"
                    label="巡查日期"
                    class="from-item-block"
                >
                    {{ formdata.dataDic.CJSJ }}
                </el-form-item>
                <el-form-item
                    prop="YCLX"
                    label="异常类型"
                    class="from-item-block"
                >
                    {{ formdata.dataDic.YCLX }}
                </el-form-item>
                <el-form-item
                    prop="SJMS"
                    label="情况描述"
                    class="from-item-block"
                >
                    <div>{{ formdata.dataDic.SJMS }}</div>
                    <!-- <div class="flex flex-wrap">
                        <audio
                            v-for="(item, index) in mp3List"
                            :key="index"
                            style="margin-right: 10px"
                            :src="item.path"
                            controls
                        ></audio>
                    </div> -->
                </el-form-item>
                <el-form-item
                    prop="YCFSWZ"
                    label="异常发生位置"
                    class="from-item-block"
                >
                    {{ formdata.dataDic.YCFSWZ }}
                </el-form-item>
                <el-form-item
                    prop="YCSJ"
                    label="异常事件"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YCSJ"
                        type="textarea"
                        resize="none"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="CJRID"
                    label="巡查员"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.CJRID"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in PatrolPeopleList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.REALNAME"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="DSCQDCS"
                    label="当时采取的措施"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.DSCQDCS"
                        type="textarea"
                        resize="none"
                        :disabled="!isEdit"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        :maxlength="500"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="异常照片"
                    class="from-item-block flex flex-wrap"
                >
                    <!-- <basic-upload
                        ref="uploadFile"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        :isEdit="isEdit"
                        :maxSize="500"
                    ></basic-upload> -->
                    <div class="imgPart scrollbar">
                        <SnFileDialog :fileData="fileData"> </SnFileDialog>
                    </div>
                </el-form-item>
                <el-form-item
                    prop="YCSFJC"
                    label="异常是否解除"
                    class="from-item-block"
                >
                    <el-radio-group
                        v-model="formdata.dataDic.YCSFJC"
                        @change="changeYCSFJC"
                        :disabled="!isEdit"
                    >
                        <el-radio label="是">是</el-radio>
                        <el-radio label="否">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item prop="JCSJ" label="异常解除日期" class="from-item-block" v-show="showJCSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        type="date"
                        placeholder="异常解除日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item> -->
            </el-form>
            <el-tabs v-model="activeNum" @tab-click="errorChange()">
                <el-tab-pane label="异常跟踪" name="0"></el-tab-pane>
                <el-tab-pane label="异常处置" name="1"></el-tab-pane>
            </el-tabs>
            <div class="taskDetailPart flex-between" v-if="timeData.length">
                <div class="timePart">
                    <div ref="axisDom" class="timeline scrollbar">
                        <div class="timezone">
                            <div
                                v-for="(y, ind) in timeData"
                                :key="ind"
                                class="timelist"
                            >
                                <div
                                    :class="{
                                        currentYear: y.Year === currentYear,
                                    }"
                                    class="year"
                                >
                                    <p @click="checkYear(y)">{{ y.Year }}</p>
                                    <span></span>
                                </div>
                                <div v-for="(m, key) in y.DataList" :key="key">
                                    <!-- 'day3': d.Rwzt === 1 && d.name === '异常' -->
                                    <div
                                        v-for="(d, index) in m.List"
                                        v-show="num === key"
                                        :key="index"
                                        :class="{
                                            currentTask: d.rwid === currentTask,
                                            day0: d.Rwzt === 0,
                                            day1: d.Rwzt === 1,
                                            day2: d.Rwzt === 2,
                                            day3:
                                                d.Rwzt === 1 &&
                                                d.name !== '正常' &&
                                                d.Sjzt !== 2 &&
                                                d.Sjzt !== 3,
                                            day4:
                                                d.Rwzt === 1 &&
                                                d.name !== '正常' &&
                                                d.Sjzt === 2,
                                            day5:
                                                d.Rwzt === 1 &&
                                                d.name !== '正常' &&
                                                d.Sjzt === 3,
                                        }"
                                        class="day"
                                        @click="checkDay(d, ind, key, index)"
                                    >
                                        <p class="timeShow">
                                            {{
                                                d.Rwjssj
                                                    ? d.Rwjssj.slice(5, 11)
                                                    : ""
                                            }}
                                        </p>
                                        <span></span>
                                        <div v-if="+d.jcdxbid === 9999">
                                            <!-- <p v-if="d.Rwzt === 0" class="pstyle pstyle0">未完成</p>
                                        <p v-if="d.Rwzt === 1 && d.name === '异常'" class="pstyle pstyle3">异常</p>
                                        <p v-if="d.Rwzt === 1 && d.name === '正常'" class="pstyle pstyle1">正常</p>
                                        <p v-if="d.Rwzt === 2" class="pstyle pstyle2">已过期</p> -->
                                            <p
                                                v-if="d.Rwzt === 0"
                                                class="pstyle pstyle0"
                                            >
                                                未完成
                                            </p>
                                            <p
                                                v-if="
                                                    d.Rwzt === 1 &&
                                                    d.name !== '正常'
                                                "
                                            >
                                                <font
                                                    class="
                                                        pstyle
                                                        pstyle3
                                                        ztName
                                                    "
                                                    >异常</font
                                                >
                                                <font
                                                    v-if="
                                                        d.Sjzt !== 2 &&
                                                        d.Sjzt !== 3
                                                    "
                                                    class="shFont"
                                                    >未审核</font
                                                >
                                                <font
                                                    v-if="d.Sjzt === 2"
                                                    class="shFont"
                                                    >审核通过</font
                                                >
                                                <font
                                                    v-if="d.Sjzt === 3"
                                                    class="shFont"
                                                    >审核不通过</font
                                                >
                                            </p>
                                            <p
                                                v-if="
                                                    d.Rwzt === 1 &&
                                                    d.name === '正常'
                                                "
                                                class="pstyle pstyle1"
                                            >
                                                正常
                                            </p>
                                            <p
                                                v-if="d.Rwzt === 2"
                                                class="pstyle pstyle2"
                                            >
                                                已过期
                                            </p>
                                        </div>
                                        <div v-else>
                                            <!-- <p v-if="d.Rwzt === 0" class="pstyle pstyle0">未完成</p>
                                        <p v-if="d.Rwzt === 1" class="pstyle pstyle1">已完成</p>
                                        <p v-if="d.Rwzt === 2" class="pstyle pstyle2">已过期</p> -->
                                            <p
                                                v-if="d.Rwzt === 0"
                                                class="pstyle pstyle0"
                                            >
                                                未完成
                                            </p>
                                            <!-- <p v-if="d.Rwzt === 1" class="pstyle pstyle1">已完成</p> -->
                                            <p v-if="d.Rwzt === 1">
                                                <font
                                                    class="
                                                        pstyle
                                                        pstyle1
                                                        ztName
                                                    "
                                                    >已完成</font
                                                >
                                                <font
                                                    v-if="
                                                        d.Sjzt !== 2 &&
                                                        d.Sjzt !== 3
                                                    "
                                                    class="shFont"
                                                    >未审核</font
                                                >
                                                <font
                                                    v-if="d.Sjzt === 2"
                                                    class="shFont"
                                                    >审核通过</font
                                                >
                                                <font
                                                    v-if="d.Sjzt === 3"
                                                    class="shFont"
                                                    >审核不通过</font
                                                >
                                            </p>
                                            <p
                                                v-if="d.Rwzt === 2"
                                                class="pstyle pstyle2"
                                            >
                                                已过期
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="infoPart">
                    <img
                        width="16px"
                        class="imgState imgStateRight"
                        src="@image/monitoring_cloud/trangle.png"
                    />
                    <div class="content">
                        <commonDetail
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></commonDetail>
                    </div>
                </div>
            </div>
            <div v-else class="taskDetailPartNo">
                <SnNoData
                    width="350px"
                    :noDataState="true"
                    noDataText="暂无跟踪数据"
                    color="#e4e4e4"
                ></SnNoData>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import commonDetail from "./comp/common_detail";
export default {
    name: "rcxcycjl",
    mixins: [infoMixin],
    components: {
        commonDetail,
    },
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1201",
                dataDic: {
                    CJSJ: "",
                    YCYSBM: "",
                    YCSJ: "",
                    CJRID: "",
                    DSCQDCS: "",
                    YCYSBM: "",
                    YCSFJC: "",
                    // JCSJ: '',
                },
                PIC: [],
            },
            rules: {
                bhgljgmc: [
                    {
                        required: true,
                        message: "请填写保护管理机构名称",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            YcysList: [], // 遗产要素列表
            PatrolPeopleList: [],
            showJCSJ: false,

            //时间线 部分
            timeData: [],
            pzid: "",
            currentRow: {},
            num: 2,
            currentYear: "",
            currentTask: "",
            currentTaskType: "",
            currentTaskTitle: "",
            taskDetailData: {},
            taskMsg: {
                time: "",
                type: "",
                title: "",
                rwzt: "",
            },
            rwzt: "",
            boxHeight: 500,
            yearInd: 0,
            MonthInd: 0,
            DayInd: 0,
            tableDom: null,
            axisDom: null,
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "CCLJ",
                    name: "WDMC",
                    list: [],
                },
            },
            activeNum: 0,
            listAry: [],
        };
    },
    mounted() {
        this.GetPatrolPeople();
    },
    methods: {
        ...mapActions([
            "GetYcysList",
            "GetPatrolList",
            "GetYcjlTaskTimeList",
            "getTaskDetails",
        ]),
        GetDataNext() {
            this.getTaskTime();
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            this.formdata.PIC.map((item) => {
                let dw = item.path.substring(item.path.lastIndexOf(".") + 1);
                switch (dw) {
                    case "mp3":
                        this.fileData.mp3List.push(item.path);
                        break;
                    case "mp4":
                        this.fileData.mp4List.push(item.path);
                        break;
                    case "jpg":
                    case "png":
                    case "jpeg":
                    case "gif":
                    case "tiff":
                        this.fileData.imgList.push(item.path);
                        break;
                    default:
                        this.fileData.othersPart.list.push(item);
                        break;
                }
            });
        },
        async GetYcysData() {
            let res = await this.GetYcysList();
            this.YcysList = res.ResultValue || [];
        },
        async GetPatrolPeople() {
            let res = await this.GetPatrolList({
                funid: "",
            });
            this.PatrolPeopleList = res.ResultValue || [];
        },
        changeYCSFJC(v) {
            // if (v == 0) {
            //     this.showJCSJ = true;
            // } else {
            //     this.showJCSJ = false;
            //     this.formdata.dataDic.JCSJ = '';
            // }
        },
        errorChange() {
            // Dwlx 0 异常处置 1 异常跟踪
            this.listAry.map((item) => {
                if (item.Dwlx != this.activeNum) {
                    this.timeData = item.RwzxYear;
                    if (this.timeData.length) {
                        this.currentYear = this.timeData[0].Year;
                        this.currentMonth = this.timeData[0].DataList[0].Month;
                        this.checkDay(this.timeData[0].DataList[0].List[0]);
                    }
                }
            });
        },
        // 获取 巡查任务时间
        async getTaskTime() {
            let result = await this.GetYcjlTaskTimeList({
                ycid: this.formdata.dataDic.ID,
            });
            this.listAry = result.ResultValue;
            // Dwlx 0 异常处置 1 异常跟踪
            this.listAry.map((item) => {
                if (item.Dwlx == 1) {
                    this.timeData = item.RwzxYear;
                    if (this.timeData.length) {
                        this.currentYear = this.timeData[0].Year;
                        this.currentMonth = this.timeData[0].DataList[0].Month;
                        this.checkDay(this.timeData[0].DataList[0].List[0]);
                    }
                }
            });
        },
        checkYear(value) {
            this.currentYear = value.Year;
            this.checkDay(value.DataList[0].List[0]);
        },
        checkDay(value, key = 0, ind = 0, index = 0) {
            this.currentTask = value.rwid;
            this.yearInd = key;
            this.MonthInd = ind;
            this.DayInd = index;
            this.getDataDetail(value);
        },
        // 获取任务详情
        async getDataDetail(value) {
            this.currentTaskType = value.jcdxbid;
            let res = await this.getTaskDetails({
                jcxbid: value.jcdxbid,
                jcpzid: value.id,
                rwid: value.rwid,
            });
            this.taskMsg.time = value.Rwjssj;
            this.taskMsg.type = this.currentTaskType;
            // this.taskMsg.title = this.currentTaskTitle;
            this.taskMsg.title = value.Rwmc;

            this.taskMsg.rwzt = value.Rwzt;
            this.taskMsg.jcpzid = value.id;
            this.taskMsg.rwid = value.rwid;
            this.taskDetailData = res;
        },
        changeShzt(v) {
            if (
                this.timeData[this.yearInd] &&
                this.timeData[this.yearInd].DataList &&
                this.timeData[this.yearInd].DataList[this.MonthInd] &&
                this.timeData[this.yearInd].DataList[this.MonthInd].List &&
                this.timeData[this.yearInd].DataList[this.MonthInd].List[
                    this.DayInd
                ]
            ) {
                this.timeData[this.yearInd].DataList[this.MonthInd].List[
                    this.DayInd
                ].Sjzt = String(v);
            }
            // this.getTaskTimeList(); // 请求时间轴列表
        },
    },
};
</script>
<style scoped lang="scss">
.taskDetailPart {
    width: 100%;
    height: 600px;
    margin-top: 10px;
    position: relative;
    .timePart {
        width: calc(30% - 10px);
        position: relative;
        .imgState {
            position: absolute;
            top: 60px;
            left: 1px;
        }
        .timeline {
            width: calc(100% - 16px);
            // overflow-y: scroll;
            margin-left: 16px;
            background-color: #fff;
            height: 100%;
            border: 1px solid #dee5ed;
            padding: 10px 0;
            .timezone {
                width: 2px;
                background: #bed1e0;
                margin: 0 auto;
                -webkit-animation: heightSlide 2s linear;
                border: 1px solid transparent;
                .timelist {
                    .year {
                        position: relative;
                        margin-left: -8px;
                        margin-bottom: 18px;
                        margin-top: 20px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #fff;
                        border: 4px solid #a0a0a0;
                        &.currentYear {
                            border: 4px solid #1560dd;
                        }
                        &.currentYear p,
                        &.currentYear p:after,
                        &.currentYear span {
                            background-color: #1560dd;
                        }
                        p {
                            position: absolute;
                            margin-left: -103px;
                            margin-top: -14px;
                            background: #a0a0a0;
                            color: #ffffff;
                            border-radius: 6px;
                            padding: 0 8px;
                            font-size: 26px;
                            cursor: pointer;
                        }
                        p:after {
                            content: "";
                            width: 12px;
                            height: 12px;
                            background-color: #a0a0a0;
                            position: absolute;
                            top: 12px;
                            right: -5px;
                            transform: rotate(45deg);
                        }
                        span {
                            width: 16px;
                            height: 2px;
                            background: #a0a0a0;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }
                    .day {
                        height: 30px;
                        line-height: 30px;
                        display: block !important;
                        position: relative;
                        margin-left: -6px;
                        margin-bottom: 30px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 3px solid #fff;
                        &.currentTask .timeShow {
                            color: #1560dd;
                            font-weight: 700;
                        }
                        &.currentTask,
                        &.currentTask > span,
                        &.currentTask > div > p {
                            background-color: #1560dd;
                        }
                        .timeShow {
                            position: absolute;
                            margin-left: -50px;
                            margin-top: -10px;
                            font-size: 16px;
                            color: #45576b;
                            cursor: pointer;
                        }
                        span {
                            width: 30px;
                            height: 2px;
                            position: absolute;
                            right: -33px;
                            top: 2px;
                        }
                        div {
                            width: 70px;
                            height: 35px;
                            margin-left: 42px;
                            margin-top: -15px;
                            font-size: 26px;
                            p {
                                height: 35px;
                                line-height: 35px;
                                color: #fff;
                                border-radius: 6px;
                                cursor: pointer;
                                text-align: center;
                            }
                            .shFont {
                                font-size: 12px;
                                line-height: 18px;
                                height: 18px;
                                display: inline-block;
                                background: #fff;
                                border-radius: 5px;
                                margin: 1px;
                                width: calc(100% - 2px);
                            }
                            .ztName {
                                line-height: 22px;
                            }
                        }
                    }
                    .day0,
                    .day0 > span,
                    .day0 > div > p {
                        background-color: #ff8b5c;
                    }
                    .day1,
                    .day1 > span,
                    .day1 > div > p {
                        background-color: #96de00;
                    }
                    .day2,
                    .day2 > span,
                    .day2 > div > p {
                        background-color: #a0a0a0;
                    }
                    .day3,
                    .day3 > span,
                    .day3 > div > p {
                        background-color: #fa6f1b;
                    }
                    .day3 > div .shFont {
                        color: #fa6f1b;
                    }
                    .day4,
                    .day4 > span,
                    .day4 > div > p {
                        background-color: rgb(40, 205, 217);
                    }
                    .day4 > div .shFont {
                        color: rgb(40, 205, 217);
                    }
                    .day5,
                    .day5 > span,
                    .day5 > div > p {
                        background-color: rgb(111, 50, 255);
                    }
                    .day5 > div .shFont {
                        color: rgb(111, 50, 255);
                    }
                    .day3,
                    .day4,
                    .day5 {
                        margin-bottom: 36px;
                    }
                    .day3 > div > p,
                    .day4 > div > p,
                    .day5 > div > p {
                        height: 42px;
                        line-height: 18px;
                    }
                }
            }
            @-webkit-keyframes heightSlide {
                0% {
                    height: 0;
                }
                100% {
                    height: 300px;
                }
            }
        }
    }
    .infoPart {
        width: calc(70% - 10px);
        position: relative;
        .imgState {
            position: absolute;
            top: 60px;
            left: 1px;
        }
        .content {
            width: calc(100% - 16px);
            margin-left: 16px;
            background-color: #f5f4f9;
            height: 100%;
            border: 1px solid #dee5ed;
        }
    }
}
.taskDetailPartNo {
    position: relative;
    width: 100%;
    height: 200px;
}

.imgPart {
    width: 760px;
    max-height: 300px;
}
</style>
